import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import {
	IconButton,
	InputAdornment,
	TextField as MUITextField,
	TextFieldProps as MUITextFieldProps,
	SelectProps as MUISelectProps,
	OutlinedTextFieldProps,
	Box
} from '@mui/material';
import { useIntl } from 'react-intl';

import useStyles from './TextField.styles';
import { Icon, noop } from '../';
import { TextFieldProps, TextFieldValue } from './TextField.types';

const TextField = <Value extends TextFieldValue>(props: TextFieldProps<Value>) => {
	const {
		type,
		InputProps: {
			classes: InputPropsClasses,
			startAdornment: startAdornmentProp,
			endAdornment: endAdornmentProp,
			...otherInputProps
		} = {},
		readOnly,
		className,
		icon,
		select,
		SelectProps: { classes: SelectPropsClasses, ...otherSelectProps } = {},
		disabled,
		hideClearIcon,
		size,
		success,
		InputLabelProps: { classes: InputLabelPropsClasses, ...otherInputLabelProps } = {},
		isAutocomplete,
		onChange = noop,
		onClear = noop,
		onBlur,
		nativeAutocomplete,
		noWrapInput,
		label: labelProp = null,
		labelLocaleValues,
		containerRef,
		customOnChange,
		'data-test': dataTest,
		children,
		...others
	} = props;
	const [showPassword, setShowPassword] = useState(false);
	const classes = useStyles(props);
	const { formatMessage } = useIntl();
	const inputType = type === 'password' && showPassword ? 'text' : type;
	const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

	const handleClear = () => {
		onChange('' as Value);
		onClear();
	};

	const handleTextFieldChange: MUITextFieldProps['onChange'] = (event) => {
		onChange(event.target.value as Value);
	};

	const handleSelectChange: MUISelectProps['onChange'] = (event) =>
		onChange(event.target.value as Value);

	const inputElement = useRef<HTMLInputElement & { node?: HTMLInputElement }>(null);
	useEffect(() => {
		if (props.autoFocus && inputElement.current) {
			inputElement.current.focus();
		}
	}, [props.autoFocus]);

	const startAdornment = (icon || startAdornmentProp) && (
		<InputAdornment
			position="start"
			className={classnames(classes.adornment, classes.startAdornment)}
		>
			{icon && <Icon icon={icon} className={classnames(classes.icon, classes.startIcon)} />}
			{startAdornmentProp}
		</InputAdornment>
	);

	const withStartAdornmentClass = icon || (startAdornmentProp && !isAutocomplete);

	const showClearIcon = !hideClearIcon && !disabled && !readOnly;
	const showPasswordIcon = type === 'password';

	const endAdornment = (
		<InputAdornment position="end" className={classes.adornment}>
			{showPasswordIcon && (
				<IconButton
					onClick={handleTogglePasswordVisibility}
					className={classnames(classes.icon, classes.endIcon)}
					size="large"
					data-testid="password-toggle-button"
				>
					<Icon data-testid="password-icon" icon={showPassword ? 'eye' : 'eyeClosed'} />
				</IconButton>
			)}
			<Icon
				icon="close"
				onClick={handleClear}
				data-testid="clear-button"
				className={classnames(classes.icon, classes.endIcon, classes.closeIcon, {
					[classes.endIconVisible]: showClearIcon
				})}
			/>
			{endAdornmentProp}
		</InputAdornment>
	);

	const InputProps: OutlinedTextFieldProps['InputProps'] = {
		startAdornment,
		endAdornment,
		readOnly,
		classes: {
			root: classnames(classes.inputBaseRoot, {
				[classes.success]: success,
				[classes.noWrapInput]: noWrapInput
			}),
			input: classnames(classes.input, {
				[classes.withStartAdornment]: withStartAdornmentClass,
				[classes.small]: size === 'small',
				[classes.autocomplete]: isAutocomplete
			}),
			notchedOutline: classes.notchedOutline,
			...InputPropsClasses
		},
		...otherInputProps
	};

	const InputLabelProps = {
		classes: {
			outlined: classnames(classes.outlinedLabel, {
				[classes.withStartAdornment]: withStartAdornmentClass,
				[classes.emptyValue]: !props.value && !props.placeholder,
				[classes.success]: success
			}),
			...InputLabelPropsClasses
		},
		...otherInputLabelProps
	};

	if (children) {
		if (!disabled && !readOnly) {
			throw new Error('Can only pass children when disabled or readOnly');
		}

		if (!InputProps.components) {
			InputProps.components = {};
		}

		if (InputProps.components) {
			InputProps.components.Input = () => (
				<Box className={InputProps.classes?.input}>{children}</Box>
			);
		}

		InputLabelProps.shrink = true;
	}

	const TextFieldClassName = classnames(
		{ [classes.readOnly]: readOnly, [classes.disabled]: disabled },
		className
	);

	const SelectProps = select
		? {
				classes: {
					icon: classnames({ [classes.selectIconToRight]: !showClearIcon }),
					...SelectPropsClasses
				},
				onChange: handleSelectChange,
				...otherSelectProps
			}
		: undefined;

	const FormHelperTextProps = {
		classes: { root: classnames(classes.helperText, { [classes.success]: success }) }
	};

	const labelFormatMessageProps = { id: labelProp as string };

	const label =
		typeof labelProp === 'string' &&
		['booking.', 'common.', 'user.', 'user-saas.'].some((item) => labelProp.startsWith(item))
			? formatMessage(labelFormatMessageProps, labelLocaleValues)
			: labelProp;

	return (
		<MUITextField
			label={label}
			InputProps={InputProps}
			InputLabelProps={InputLabelProps}
			type={inputType}
			onChange={customOnChange ?? (!select ? handleTextFieldChange : undefined)}
			inputRef={inputElement}
			variant="outlined"
			className={TextFieldClassName}
			select={select}
			SelectProps={SelectProps}
			disabled={disabled}
			FormHelperTextProps={FormHelperTextProps}
			onBlur={onBlur}
			autoComplete={nativeAutocomplete === false ? 'none' : nativeAutocomplete}
			ref={containerRef}
			data-test={dataTest}
			{...others}
		>
			{select && SelectProps && SelectProps.children}
		</MUITextField>
	);
};

TextField.displayName = 'FomfTextField';

export default TextField;
